export const LOGIN = 'auth/LOGIN'
export const UPDATE = 'auth/UPDATE'
export const LOGOUT = 'auth/LOGOUT'

const initialState = () => ({
  token: null,
  email: null
})

const reducer = (state = initialState(), { type, data }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        token: data.token
      }
    case UPDATE:
      return {
        ...state,
        ...data,
        token: state.token
      }
    case LOGOUT:
      return initialState()
    default:
      return state
  }
}

export default reducer

export const authLogin = ({ token }) => {
  return {
    type: LOGIN,
    data: { token },
  }
}

export const authUpdate = (data) => {
  return {
    type: UPDATE,
    data
  }
}

export const authSetEmail = (email) => {
  return {
    type: UPDATE,
    data: {
      email
    }
  }
}

export const authLogout = () => {
  return {
    type: LOGOUT,
  }
}
