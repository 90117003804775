import React from 'react'
import { createRoot } from 'react-dom/client'
import 'style/index.scss'
import App from './app'
// import reportWebVitals from './report-web-vitals'
import initReactFastclick from 'react-fastclick'
initReactFastclick()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
