import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './auth'
import userReducer from './user'

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['simulator']
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  user: userReducer
})

export const store = createStore(rootReducer)
export const persistor = persistStore(store)

export default store
