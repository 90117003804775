export const REPLACE = 'user/REPLACE'
export const UPDATE = 'user/UPDATE'
export const LOGOUT = 'auth/LOGOUT'

const initialState = () => (null)

const reducer = (state = initialState(), { type, data }) => {
  switch (type) {
    case REPLACE: 
      return data
    case UPDATE:
      return state ? {
        ...state,
        ...data
      } : { ...data }
    case LOGOUT:
      return initialState()
    default:
      return state
  }
}

export default reducer

export const userUpdate = (data) => {
  return {
    type: UPDATE,
    data
  }
}

export const userReplace = (data) => {
  return {
    type: REPLACE,
    data
  }
}