import { Suspense, lazy } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { store, persistor } from 'store'
import AppLoading from 'components/app-loading'
import 'lib/i18n'

const Connect = lazy(() => import('./connect'))
const Analytics = lazy(() => import('components/analytics'))
const AuthWall = lazy(() => import('components/auth-wall'))
const ScrollToTop = lazy(() => import('components/scroll-to-top'))

const App = () => {

  return (
    <Suspense fallback={<AppLoading />}>
      <Provider store={store}>
        <PersistGate loading={<AppLoading />} persistor={persistor}>
          <Router basename={'/'}>
            <AuthWall>
              <Connect />
            </AuthWall>
            {process.env.NODE_ENV === 'production' && <Analytics />}
            <ScrollToTop />
          </Router>
        </PersistGate>
      </Provider>
    </Suspense>
  )

}

export default App
