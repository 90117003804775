import { Spinner } from 'reactstrap'

const AppLoading = () => {
  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Spinner color='secondary' />
    </div>
  )
}

export default AppLoading
